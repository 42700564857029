import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyCvepDdSpQHm6IX_8yZjR04Bf7b31uiPrU",
//   authDomain: "neurolink-internal.firebaseapp.com",
//   databaseURL: "https://neurolink-internal.firebaseio.com",
//   projectId: "neurolink-internal",
//   storageBucket: "neurolink-internal.appspot.com",
//   messagingSenderId: "266219558980",
//   appId: "1:266219558980:web:d9e8b5456f0398724e4411",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCvepDdSpQHm6IX_8yZjR04Bf7b31uiPrU",
  authDomain: "neurolink-internal.firebaseapp.com",
  databaseURL: "https://neurolink-internal.firebaseio.com",
  projectId: "neurolink-internal",
  storageBucket: "neurolink-internal.appspot.com",
  messagingSenderId: "266219558980",
  appId: "1:266219558980:web:527b5391a96e63764e4411",
};

const app = initializeApp(firebaseConfig);

export default app;
