import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from "./component/Login";
import AlarmClockList from "./page/AlarmClockList";
import app from "./firebase";
import { collection, doc, getFirestore, getDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import useBeforeUnload from "./hook/useBeforeUnload";

function App() {
  const [user, setUser] = useState(null);
  const [schoolId, setSchoolId] = useState("");
  const [department, setDepartment] = useState("櫃檯");
  const auth = getAuth(app);
  const db = getFirestore(app);

  useBeforeUnload(() => {});
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user.email?.includes("@nl.com")) {
        let q = doc(db, "machineUsers", user.email);
        getDoc(q).then((doc) => {
          if (doc.exists) {
            setSchoolId(doc.data().school);
            setDepartment(doc.data().department || "櫃檯");
          }
        });
      }

      setUser(user);
    });
  }, [auth]);

  return (
    <div className="App">
      <header className="App-header">
        {user && schoolId !== "" ? <AlarmClockList schoolId={schoolId} department={department} /> : <Login />}
      </header>
    </div>
  );
}

export default App;
