import React, { useState, useEffect } from "react";

import app from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";

const YourInnerComponent = ({ draft, schoolId, setCurrentCourseDay, allEnglishClassList }) => {
  // replace all space in name

  return (
    <div className="border border-primary">
      <h5>
        {draft.classID} :
        {draft.startDate?.toDate().toLocaleTimeString("zh-TW", {
          hour: "2-digit",
          minute: "2-digit",
        })}{" "}
        -{" "}
        {draft.endDate?.toDate().toLocaleTimeString("zh-TW", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </h5>

      <div className="d-flex flex-wrap">
        {allEnglishClassList
          .find((item) => item.docId == draft.classChosenID)
          ?.studentNames.map((student) => (
            <div className="mx-1" key={draft.classChosenID + student}>
              <p>{student}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

const DayColumn = ({
  day,
  item,
  index,
  thisDate,
  draftsInThisDay,
  setCurrentPastDate,
  setCurrentCourseDay,
  schoolId,
  DOneToTwentyFour,
  allEnglishClassList,
  allAbsentStudnetList,
}) => {
  return (
    <div className="mx-2">
      <div className="inner-container d-flex">
        {index === 0 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`<`}
          </button>
        )}
        <h3 className="date-text">
          {item} {thisDate.toLocaleDateString("zh-TW", { month: "short", day: "numeric", weekday: "short" })}
        </h3>
        {index === 5 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`>`}
          </button>
        )}
      </div>

      {allAbsentStudnetList
        .filter((item) => item.leaveDate.toDate().toDateString() === thisDate.toDateString())
        .map((item) => {
          return (
            <div className="alert alert-danger" role="alert">
              {item.studentInfo.studentName + "請假"}:<p style={{ fontSize: 10 }}>{item.absentCourses.join(",")}</p>
            </div>
          );
        })}

      <div>
        {draftsInThisDay.map((draft, index) => (
          <YourInnerComponent
            draft={draft}
            schoolId={schoolId}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
            allEnglishClassList={allEnglishClassList}
            allAbsentStudnetList={allAbsentStudnetList}
          />
        ))}
      </div>
    </div>
  );
};

const CheckAttendance = ({ schoolId }) => {
  const db = getFirestore(app);

  const [diaryDraftList, setDiaryDraftList] = useState([]);
  const [currentCourseDay, setCurrentCourseDay] = useState("");

  const [DOneToTwentyFour, setDOneToTwentyFour] = useState([]);
  var myCurrentDate = new Date();
  const [currentPastDate, setCurrentPastDate] = useState(new Date());

  const [allEnglishClassList, setAllEnglishClassList] = useState([]);

  const [allAbsentStudnetList, setAllAbsentStudnetList] = useState([]);

  const getAllStudentList = async () => {
    let q = query(collection(db, `schools/${schoolId}/schoolClasses`), where("status", "==", "開班ing"));
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllEnglishClassList(temp);
  };

  const fetchAllAbsentStudnetList = async () => {
    let pastDate = currentPastDate;
    let deductable = pastDate.getDay() - 1;
    if (pastDate.getDay == 0) {
      deductable = 7;
    }
    pastDate.setDate(pastDate.getDate() - deductable);
    pastDate.setHours(0, 0, 0, 0);

    let futureDate = new Date(pastDate);
    futureDate.setDate(futureDate.getDate() + 7);

    let q = query(
      collection(db, `absentStudentRecord`),
      where("schoolId", "==", schoolId),
      where("leaveDate", ">=", pastDate),
      where("leaveDate", "<", futureDate)
    );
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setAllAbsentStudnetList(temp);
    console.log(temp);
  };

  const getDraftDiaryList = async () => {
    // var pastDate = getPastDate();
    var pastDate = currentPastDate;
    var deductable = pastDate.getDay() - 1;
    if (pastDate.getDay == 0) {
      deductable = 7;
    }
    pastDate.setDate(pastDate.getDate() - deductable);
    pastDate.setHours(0, 0, 0, 0);

    var futureDate = new Date(pastDate);
    futureDate.setDate(futureDate.getDate() + 7);

    let q = query(
      collection(db, "bigDraftDiaryList"),
      where("schoolId", "==", schoolId),
      where("startDate", ">=", pastDate),
      where("startDate", "<", futureDate),
      orderBy("startDate", "asc")
    );

    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDiaryDraftList(temp);
  };

  const fetchDOneToTwentyFour = async () => {
    let q = query(collection(db, "DOneToTwentyFourCollection"));
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDOneToTwentyFour(temp);
  };

  useEffect(() => {
    getDraftDiaryList();
    fetchDOneToTwentyFour();
    getAllStudentList();
    fetchAllAbsentStudnetList();
  }, [currentPastDate]);

  return (
    <div style={{ display: "flex" }}>
      {[
        { day: "Mon", items: ["Item 1", "Item 2", "Item 3"] },
        { day: "Tue", items: ["Item 4", "Item 5", "Item 6"] },
        { day: "Wed", items: ["Item 7", "Item 8", "Item 9"] },
        { day: "Thu", items: ["Item 10", "Item 11", "Item 12"] },
        { day: "Fri", items: ["Item 13", "Item 14", "Item 15"] },
        { day: "Sat", items: ["Item 16", "Item 17", "Item 18"] },
      ].map((dayData, index) => {
        let draftsInThisDay = diaryDraftList.filter((draft) => {
          return draft.startDate?.toDate().getDay() == index + 1;
        });

        let thisDate = new Date(currentPastDate);
        thisDate.setDate(thisDate.getDate() + index);

        return (
          <DayColumn
            key={index}
            day={dayData.day}
            draftsInThisDay={draftsInThisDay}
            setCurrentPastDate={setCurrentPastDate}
            item={dayData.day}
            index={index}
            thisDate={thisDate}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
            allEnglishClassList={allEnglishClassList}
            allAbsentStudnetList={allAbsentStudnetList}
          />
        );
      })}
    </div>
  );
};

export default CheckAttendance;
