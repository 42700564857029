import React from "react";

function AlarmClockDetails({ alarm }) {
  return (
    <div className="container mt-5">
      <h1 className="mb-4">{alarm.time}</h1>
      <p>{alarm.description}</p>
      <p>Active: {alarm.active ? "Yes" : "No"}</p>
    </div>
  );
}

export default AlarmClockDetails;
