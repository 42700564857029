import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";

import app from "../firebase";
import { act } from "react-dom/test-utils";
const data = [
  { name: "John", city: "New York" },
  { name: "Jane", city: "San Francisco" },
];

const DataTable = ({ data }) => {
  return (
    <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>標題</th>
          <th>內文</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>Day {index + 1} </td>

            <td>
              {item.maintext.split("\n").map((text, index) => (
                <div key={index}>{text}</div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const BigCGCategory = ["EF", "Science", "Reach", "Drama", "Writing", "NK", "GN", "UC", "PC", "TT", "WE", "IS", "C21", "其他", "寒暑假"];

const SmallCGCategory = [
  "EF1.1",
  "EF1.2",
  "EF2.1",
  "EF2.2",
  "EF3.1",
  "EF3.2",
  "EF4.1",
  "EF4.2",
  "EF5.1",
  "EF5.2",
  "EF6.1",
  "EF6.2",
  "EF7.1",
  "EF7.2",
  "EF8.1",
  "EF8.1(A)",
  "EF8.1(B)",
  "EF8.2",
  "EF8.2(A)",
  "EF8.2(B)",
  "EF9.1",
  "EF9.1(A)",
  "EF9.1(B)",
  "EF9.2",
  "EF9.2(A)",
  "EF9.2(B)",
  "EF10.1",
  "EF10.2",
  "EF11.1",
  "EF11.2",
  "EF12.1",
  "EF12.2",
  "EF1AD",
  "EF1.2AD",
  "EF2.1AD",
  "EF2.2AD",
  "EF3.1AD",
  "EF3.2AD",

  "Reach H 1A",
  "Reach H 1B",
  "Reach H 2A",
  "Reach H 2B",
  "Reach H 3A",
  "Reach H 3B",

  "PW1.1(Science)",
  "PW1.2(Science)",
  "PW1.3(Science)",
  "PW1.4(Science)",
  "SC2A.1(Science)",
  "SC2A.2(Science)",
  "SC2B.1(Science)",
  "SC2B.2(Science)",
  "ST1.1(NK)",
  "ST1.2(NK)",
  "ST1.3(NK)",
  "ST2.1(NK)",
  "ST2.2(NK)",
  "ST2.3(NK)",
  "ST3.1(NK)",
  "ST3.2(NK)",
  "ST3.3(NK)",
  "FF1A.1(NK)",
  "FF1A.2(NK)",
  "FF1B.1(NK)",
  "FF1B.2(NK)",
  "FF2A.1(NK)",
  "FF2A.2(NK)",
  "FF2B.1(NK)",
  "FF2B.2(NK)",
  "FF3A.1(NK)",
  "FF3A.2(NK)",
  "FF3B.1(NK)",
  "FF3B.2(NK)",

  "FF1A.a(NK)",
  "FF1A.b(NK)",
  "FF1B.a(NK)",
  "FF1B.b(NK)",
  "FF2A.a(NK)",
  "FF2A.b(NK)",
  "FF2B.a(NK)",
  "FF2B.b(NK)",
  "FF3A.a(NK)",
  "FF3A.b(NK)",
  "FF3B.a(NK)",
  "FF3B.b(NK)",
  "Writing",
  "Drama",
  "GN3.1",
  "GN3.2",
  "GN3.3",
  "GN3.4",
  "GN3.5/4.1",
  "GN4.1",
  "GN4.2",
  "GN4.3",
  "GN4.4",
  "GN4.5/5.1",
  "GN5.1",
  "GN5.2",
  "GN5.3",
  "GN5.4",
  "GN5.5/6.1",
  "GN6.1",
  "GN6.2",
  "GN6.3",
  "GN6.4",
  "GN6.5",
  "GN4.1/4.2",
  "GN4.2/4.3",
  "GN4.3/4.4",
  "GN4.4/4.5",
  "GN5.1/5.2",
  "GN5.2/5.3",
  "GN5.3/5.4",
  "GN5.4/5.5",
  "GN6.1/6.2",
  "GN6.2/6.3",
  "GN6.3/6.4",
  "GN6.4/6.5",
  "GN4.1(a)",
  "GN4.1(b)",
  "GN4.2(a)",
  "GN4.2(b)",
  "GN4.3(a)",
  "GN4.3(b)",
  "GN4.4(a)",
  "GN4.4(b)",
  "GN4.5/5.1(a)",
  "GN4.5/5.1(b)",

  "GN5.1/5.2(a)",
  "GN5.1/5.2(b)",
  "GN5.2/5.3(a)",
  "GN5.2/5.3(b)",
  "GN5.3/5.4(a)",
  "GN5.3/5.4(b)",
  "GN5.4/5.5(a)",
  "GN5.4/5.5(b)",

  "GN5.1(a)",
  "GN5.1(b)",
  "GN5.2(a)",
  "GN5.2(b)",
  "GN5.3(a)",
  "GN5.3(b)",
  "GN5.4(a)",
  "GN5.4(b)",
  "GN5.5/6.1(a)",
  "GN5.5/6.1(b)",
  "GN6.1(a)",
  "GN6.1(b)",
  "GN6.2(a)",
  "GN6.2(b)",
  "GN6.3(a)",
  "GN6.3(b)",
  "GN6.4(a)",
  "GN6.4(b)",
  "GN6.5(a)",
  "GN6.5(b)",

  "GN6.1/6.2(a)",
  "GN6.1/6.2(b)",
  "GN6.2/6.3(a)",
  "GN6.2/6.3(b)",
  "GN6.3/6.4(a)",
  "GN6.3/6.4(b)",
  "GN6.4/6.5(a)",
  "GN6.4/6.5(b)",

  "PC",
  "UC2.1",
  "UC2.2",
  "UC2.3",
  "UC3.1",
  "UC3.2",
  "UC3.3",
  "UC4.1",
  "UC4.2",
  "UC4.3",
  "UC2.1(a)(GJ)",
  "UC2.2(a)(GJ)",
  "UC2.3(a)(GJ)",
  "UC2.1(b)(GJ)",
  "UC2.2(b)(GJ)",
  "UC2.3(b)(GJ)",

  "UC3.1(a)(GJ)",
  "UC3.2(a)(GJ)",
  "UC3.3(a)(GJ)",
  "UC3.1(b)(GJ)",
  "UC3.2(b)(GJ)",
  "UC3.3(b)(GJ)",

  "UC4.1(a)(GJ)",
  "UC4.2(a)(GJ)",
  "UC4.3(a)(GJ)",
  "UC4.1(b)(GJ)",
  "UC4.2(b)(GJ)",
  "UC4.3(b)(GJ)",

  "TT3.1(a)",
  "TT3.1(b)",
  "TT3.2(a)",
  "TT3.2(b)",
  "TT4.1(a)",
  "TT4.1(b)",
  "TT4.2(a)",
  "TT4.2(b)",
  "WE1.1(a)",
  "WE1.1(b)",
  "WE1.2(a)",
  "WE1.2(b)",
  "WE1.3(a)",
  "WE1.3(b)",
  "WE2.1(a)",
  "WE2.1(b)",
  "WE2.2(a)",
  "WE2.2(b)",
  "WE2.3(a)",
  "WE2.3(b)",
  "WE3.1(a)",
  "WE3.1(b)",
  "WE3.2(a)",
  "WE3.2(b)",
  "WE3.3(a)",
  "WE3.3(b)",
  "IS-IN.1(a)",
  "IS-IN.2(b)",
  "IS-IN.3(a)",
  "IS-IN.4(b)",
  "IS-IN.5(a)",

  "IS-UP.1(b)",
  "IS-UP.2(a)",
  "IS-UP.3(b)",
  "IS-UP.4(a)",
  "IS-UP.5(b)",
  "WES1.1(Science)",

  "WES1.2(Science)",

  "WES2.1(Science)",

  "WES2.2(Science)",

  // "WES1.1a(Science)",
  // "WES1.1b(Science)",
  // "WES1.2a(Science)",
  // "WES1.2b(Science)",
  // "WES2.1a(Science)",
  // "WES2.1b(Science)",
  // "WES2.2a(Science)",
  // "WES2.2b(Science)",
  "C21-2.1",
  "C21-2.2",
  "C21-2.1(a)",
  "C21-2.1(b)",
  "C21-2.2(a)",
  "C21-2.2(b)",
  "C21-3.1(a)",
  "C21-3.1(b)",
  "C21-3.2(a)",
  "C21-3.2(b)",
  "C21-4.1(a)",
  "C21-4.1(b)",
  "C21-4.2(a)",
  "C21-4.2(b)",
  "其他",
  "寒暑假",
  "EF1(ShortCut)",
  "EF2(ShortCut)",
  "EF3(ShortCut)",
];

const ECBList = () => {
  const [selectedButton, setSelectedButton] = useState(null);
  const db = getFirestore(app);
  const [currentCategoryInfo, setCurrentCategoryInfo] = useState({
    bigCG: "EF",
  });

  const [courseGuideData, setCourseGuideData] = useState([]);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const fetchECB = async (smallCG) => {
    const q = query(collection(db, "CourseGuideEditList"), where(`smallcoursetype`, "==", smallCG), orderBy("name", "asc"));

    const querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      temp.push({
        id: doc.id,
        maintext: doc.data().maintext,
      });
    });
    setCourseGuideData(temp);
  };

  return (
    <div>
      {/* <ButtonGroup buttons={["Group 1", "Group 2", "Group 3"]} onClick={handleButtonClick} /> */}
      <div>
        <ButtonGroup className="mb-3" aria-label="Days of the week">
          {BigCGCategory.map((item) => (
            <Button
              key={item}
              variant={item === currentCategoryInfo.bigCG ? "outline-primary" : "outline-secondary"}
              onClick={() => {
                setCurrentCategoryInfo({ bigCG: item });
              }}
              active={item === currentCategoryInfo.bigCG}
            >
              {item}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      {currentCategoryInfo.bigCG && (
        <div className="d-flex flex-wrap">
          {SmallCGCategory.filter((item) => item.includes(currentCategoryInfo.bigCG)).map((item) => (
            <Button
              key={item}
              variant={item === currentCategoryInfo.smallCG ? "outline-primary" : "outline-secondary"}
              onClick={() => {
                setCurrentCategoryInfo({ ...currentCategoryInfo, smallCG: item });
                fetchECB(item);
              }}
              active={item === currentCategoryInfo.smallCG}
            >
              {item}
            </Button>
          ))}
        </div>
      )}

      <div>
        <DataTable data={courseGuideData} />
      </div>
    </div>
  );
};

export default ECBList;
