import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import AlarmClockCard from "../component/alarm/AlarmClockCard";

function AlarmActualList({
  allAlarms,
  currentDayOfWeek,
  department,
  schoolId,
  handleViewTurnedActive,
  handleEditTimeChange,
  setAddShowModal,
  updateAlarmInFirestore,
  deleteAlarmInFirestore,
  createTextToSpeech,
}) {
  const [selectedDay, setSelectedDay] = React.useState(currentDayOfWeek);

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const getSelectedDayObj = (selectedDay) => {
    const selectedDayObj = new Date();
    let selectedDayNum;

    switch (selectedDay) {
      case "Mon":
        selectedDayNum = 1;
        break;
      case "Tue":
        selectedDayNum = 2;
        break;
      case "Wed":
        selectedDayNum = 3;
        break;
      case "Thu":
        selectedDayNum = 4;
        break;
      case "Fri":
        selectedDayNum = 5;
        break;
      case "Sat":
        selectedDayNum = 6;
        break;
      case "Sun":
        selectedDayNum = 0;
        break;
      default:
        selectedDayNum = -1;
    }
    selectedDayObj.setDate(selectedDayObj.getDate() + ((selectedDayNum - selectedDayObj.getDay() + 7) % 7));
    return selectedDayObj;
  };

  return (
    <div>
      <Button type="button" className="btn btn-primary" onClick={() => setAddShowModal(true)}>
        新增鬧鐘
      </Button>
      <div>
        <ButtonGroup className="mb-3" aria-label="Days of the week">
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <Button
              key={day}
              variant={day === currentDayOfWeek ? "outline-primary" : "outline-secondary"}
              onClick={() => handleDayClick(day)}
              active={day === selectedDay}
            >
              {day} - {getSelectedDayObj(day)?.toLocaleDateString()}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <h1 className="mb-4">
        {schoolId} - {department} 今日鬧鐘
      </h1>

      <div>
        {selectedDay !== "" && (
          <div>
            {allAlarms
              .filter((alarm) => {
                if (alarm.repeat && alarm.repeat.length > 0) {
                  return alarm.repeat?.includes(selectedDay);
                } else if (alarm.oneOff) {
                  let checkCondition =
                    alarm.datetime.toDate().toDateString("en-US") == getSelectedDayObj(selectedDay).toDateString("");

                  return checkCondition;
                }
              })
              .map((alarm) => (
                <AlarmClockCard
                  key={alarm.id}
                  alarm={alarm}
                  handleViewTurnedActive={handleViewTurnedActive}
                  handleEditTimeChange={handleEditTimeChange}
                  handleSaveClick={updateAlarmInFirestore}
                  handleDeleteClick={deleteAlarmInFirestore}
                  createTextToSpeech={createTextToSpeech}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AlarmActualList;
