import React, { useState, useEffect } from "react";

import app from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";

const YourInnerComponent = ({ draft, schoolId, setCurrentCourseDay, DOneToTwentyFour }) => {
  // replace all space in name
  let noStringName = draft?.courseGuideContent?.name?.replace(" ", "");
  let correspondingDay = { actionItems: [] };
  let IsDay202324 = false;
  if (noStringName?.includes("EF")) {
    // This is EF, find the corresponding Day
    // find the last two digits
    let lastTwoDigits = noStringName.slice(-2);

    IsDay202324 = lastTwoDigits == "01" || lastTwoDigits == "20" || lastTwoDigits == "23" || lastTwoDigits == "24";
    let CurcorrespondingDay = DOneToTwentyFour.find((item) => {
      return item.title == "Day" + lastTwoDigits;
    });
    if (CurcorrespondingDay) {
      correspondingDay = CurcorrespondingDay;
    }
    // Also need to highlight Day 20 .
  } else {
    // no corresponding day
    if (noStringName?.includes("Reach")) {
      let lastTwoDigits = noStringName.slice(-2);
      IsDay202324 =
        lastTwoDigits == "11" ||
        lastTwoDigits == "12" ||
        lastTwoDigits == "23" ||
        lastTwoDigits == "24" ||
        lastTwoDigits == "35" ||
        lastTwoDigits == "36" ||
        lastTwoDigits == "47" ||
        lastTwoDigits == "48";
    }
  }

  return (
    <div className="jumbotron">
      <h3
        style={{
          fontSize: 24,
          fontWeight: "bold",
          // marginBottom: 10,
          backgroundColor: IsDay202324 ? "orange" : "#FFF",
          color: IsDay202324 ? "white" : "black",
        }}
      >
        {draft.classID} 📘 {draft.courseGuideContent?.name}
      </h3>

      <p>
        {draft.startDate?.toDate().toLocaleTimeString("zh-TW", { hour: "2-digit", minute: "2-digit" })} -{" "}
        {draft.endDate?.toDate().toLocaleTimeString("zh-TW", { hour: "2-digit", minute: "2-digit" })}
      </p>
      <div>
        {correspondingDay.actionItems.length !== 0 ? (
          correspondingDay.actionItems
            .filter((ac) => ac.role === "LA")
            .map((ac, acIndex) => (
              <p style={{ fontSize: 16, marginVertical: 5 }} key={ac.text}>
                {acIndex + 1}. {ac.text}
              </p>
            ))
        ) : (
          <p> </p>
        )}
      </div>
      <div>
        {correspondingDay.actionItems.length !== 0 ? (
          correspondingDay.actionItems
            .filter((ac) => ac.role === "NHO")
            .map((ac, acIndex) => (
              <p style={{ fontSize: 16, marginVertical: 5 }} key={ac.text}>
                {acIndex + 1}. {ac.text}
              </p>
            ))
        ) : (
          <p> </p>
        )}
      </div>
    </div>
  );
};

const DayColumn = ({
  day,
  item,
  index,
  thisDate,
  draftsInThisDay,
  setCurrentPastDate,
  setCurrentCourseDay,
  schoolId,
  DOneToTwentyFour,
}) => {
  return (
    <div className="mx-2">
      <div className="inner-container d-flex">
        {index === 0 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`<`}
          </button>
        )}
        <h3 className="date-text">
          {item} {thisDate.toLocaleDateString("zh-TW", { month: "short", day: "numeric", weekday: "short" })}
        </h3>
        {index === 5 && (
          <button
            onClick={() => {
              setCurrentPastDate((prev) => {
                var newDate = new Date(prev);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
              });
            }}
            className="button"
          >
            {`>`}
          </button>
        )}
      </div>

      <div>
        {draftsInThisDay.map((draft, index) => (
          <YourInnerComponent
            draft={draft}
            schoolId={schoolId}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
          />
        ))}
      </div>
    </div>
  );
};

const NextWeekAction = ({ schoolId }) => {
  const db = getFirestore(app);

  const [diaryDraftList, setDiaryDraftList] = useState([]);
  const [currentCourseDay, setCurrentCourseDay] = useState("");

  const [DOneToTwentyFour, setDOneToTwentyFour] = useState([]);
  var myCurrentDate = new Date();
  const [currentPastDate, setCurrentPastDate] = useState(new Date());

  const getDraftDiaryList = async () => {
    // var pastDate = getPastDate();
    var pastDate = currentPastDate;
    var deductable = pastDate.getDay() - 1;
    if (pastDate.getDay == 0) {
      deductable = 7;
    }
    pastDate.setDate(pastDate.getDate() - deductable);
    pastDate.setHours(0, 0, 0, 0);

    var futureDate = new Date(pastDate);
    futureDate.setDate(futureDate.getDate() + 7);

    let q = query(
      collection(db, "bigDraftDiaryList"),
      where("schoolId", "==", schoolId),
      where("startDate", ">=", pastDate),
      where("startDate", "<", futureDate),
      orderBy("startDate", "asc")
    );

    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDiaryDraftList(temp);
  };

  const fetchDOneToTwentyFour = async () => {
    let q = query(collection(db, "DOneToTwentyFourCollection"));
    let querySnapshot = await getDocs(q);
    let temp = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        temp.push({ docId: doc.id, ...doc.data() });
      }
    });
    setDOneToTwentyFour(temp);
  };

  useEffect(() => {
    getDraftDiaryList();
    fetchDOneToTwentyFour();
  }, [currentPastDate]);

  return (
    <div style={{ display: "flex" }}>
      {[
        { day: "Mon", items: ["Item 1", "Item 2", "Item 3"] },
        { day: "Tue", items: ["Item 4", "Item 5", "Item 6"] },
        { day: "Wed", items: ["Item 7", "Item 8", "Item 9"] },
        { day: "Thu", items: ["Item 10", "Item 11", "Item 12"] },
        { day: "Fri", items: ["Item 13", "Item 14", "Item 15"] },
        { day: "Sat", items: ["Item 16", "Item 17", "Item 18"] },
      ].map((dayData, index) => {
        let draftsInThisDay = diaryDraftList.filter((draft) => {
          return draft.startDate?.toDate().getDay() == index + 1;
        });

        let thisDate = new Date(currentPastDate);
        thisDate.setDate(thisDate.getDate() + index);

        return (
          <DayColumn
            key={index}
            day={dayData.day}
            draftsInThisDay={draftsInThisDay}
            setCurrentPastDate={setCurrentPastDate}
            item={dayData.day}
            index={index}
            thisDate={thisDate}
            setCurrentCourseDay={setCurrentCourseDay}
            DOneToTwentyFour={DOneToTwentyFour}
          />
        );
      })}
    </div>
  );
};

export default NextWeekAction;
