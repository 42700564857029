// useBeforeUnload.js
import { useEffect } from "react";

const useBeforeUnload = (handler) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      handler();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handler]);
};

export default useBeforeUnload;
