import React, { useState, useEffect } from "react";
import AlarmClockDetails from "../component/AlarmClockDetails";
import soundFile from "../asset/alarm-clock-short.mp3";
import AddAlarmModal from "../component/alarm/AddAlarmModal";
import AlarmClockCard from "../component/alarm/AlarmClockCard";

import app from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  orderBy,
  updateDoc,
  addDoc,
  Timestamp,
  onSnapshot,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import AlarmActualList from "./AlarmActualList";
import ECBList from "./ECBList";
import NextWeekAction from "./NextWeekAction";
import CheckAttendance from "./CheckAttendance";
// I need to know the test situation.
function AlarmClockModal({
  alarms,
  setAlarms,
  showModal,
  schoolUsers,
  handleSnoozeClick,
  handleSetToDoneClick,
  handleClose,
  createTextToSpeech,
}) {
  const [isAlarmPlaying, setIsAlarmPlaying] = useState(false);
  const [queuedAlarms, setQueuedAlarms] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);

  const stopAlarms = () => {
    setIsAlarmPlaying(false);
    setQueuedAlarms([]);
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
    }
  };

  const playNextAlarm = async (alarmsToPlay, queuedAlarmsToPlay) => {
    if (alarmsToPlay.length === 0) {
      setIsAlarmPlaying(false);
      if (queuedAlarmsToPlay.length > 0) {
        setQueuedAlarms([]);
        playNextAlarm(queuedAlarmsToPlay, []);
      }
      return;
    }

    setIsAlarmPlaying(true);
    const alarm = alarmsToPlay[0];

    if (alarm.audioUrl) {
      const audio = new Audio(alarm.audioUrl);
      setCurrentAudio(audio);
      console.log("alarm", alarm.audioUrl, alarm.id, alarm.docId);
      await new Promise((resolve) => {
        audio.play();
        audio.addEventListener("ended", () => setTimeout(resolve, 1000));
      });
    } else {
      createTextToSpeech(alarm.description, alarm);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    playNextAlarm(alarmsToPlay.slice(1), queuedAlarmsToPlay);
  };

  useEffect(() => {
    if (alarms.length === 0) {
      handleClose();
      stopAlarms();
    } else if (!isAlarmPlaying) {
      // playAlarms(0);
      playNextAlarm(alarms, []);
    } else {
      setQueuedAlarms(alarms);
    }
  }, [alarms]);

  useEffect(() => {
    let modalTimeout = setInterval(() => {
      if (!isAlarmPlaying) {
        // playAlarms(0);
        playNextAlarm(alarms, []);
      } else {
        setQueuedAlarms(alarms);
      }
    }, 15000);

    return () => {
      clearInterval(modalTimeout);
    };
  }, [alarms]);

  const handleSnooze = (alarm, time = 5, alarmTitle) => {
    console.log("znooe,", alarmTitle);

    handleSnoozeClick(alarm, time, alarmTitle);

    // Filter out the snoozed alarm from the alarms array
    const filteredAlarms = alarms.filter((a) => a.id !== alarm.id);
    setAlarms(filteredAlarms);
    console.log("filteredAlarms", filteredAlarms);

    // Filter out the snoozed alarm from the queuedAlarms array
    const filteredQueuedAlarms = queuedAlarms.filter((a) => a.id !== alarm.id);
    setQueuedAlarms(filteredQueuedAlarms);
    console.log("filteredQueuedAlarms", filteredQueuedAlarms);

    // If it's the last alarm, stop all audio and clear all alarms
    if (alarms.length === 1 && queuedAlarms.length === 0) {
      stopAlarms();
    }
  };

  const handleSetToDone = (alarm) => {
    handleSetToDoneClick(alarm);
  };

  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>提醒您！</Modal.Title>
        <p>{JSON.stringify(alarms.map((a) => a.id))}</p>
      </Modal.Header>
      <Modal.Body>
        {alarms.map(
          (alarm) =>
            alarm.active && (
              <div key={alarm.id} className="d-flex flex-row justify-content-between align-items-center mb-3">
                <p className="m-0">{alarm.description}</p>
                <div>
                  {schoolUsers?.map((user) => (
                    <Button variant="primary" size="sm" onClick={() => handleSetToDone(alarm)}>
                      {user.displayName} Complete
                    </Button>
                  ))}
                  <Button
                    variant="secondary"
                    className="ms-2"
                    size="sm"
                    onClick={() => {
                      // stopAlarms();
                      handleSnooze(alarm, 1, alarm.title);
                    }}
                  >
                    貪睡一分鐘
                  </Button>
                  <Button
                    variant="secondary"
                    className="ms-2"
                    size="sm"
                    onClick={() => {
                      // stopAlarms();
                      handleSnooze(alarm, 3, alarm.title);
                    }}
                  >
                    貪睡三分鐘
                  </Button>
                  <Button
                    variant="secondary"
                    className="ms-2"
                    size="sm"
                    onClick={() => {
                      // stopAlarms();
                      handleSnooze(alarm, 5, alarm.title);
                    }}
                  >
                    貪睡五分鐘
                  </Button>
                </div>
              </div>
            )
        )}
      </Modal.Body>
    </Modal>
  );
}

function AlarmClockList({ schoolId, department }) {
  // const department = "櫃檯";
  //   const [timeouts, setTimeouts] = useState([]);
  const [activeAlarms, setActiveAlarms] = useState([]);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const textToSpeech = httpsCallable(functions, "textToSpeech");

  const [showAddModal, setAddShowModal] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const db = getFirestore(app);
  const [todayAlarm, setTodayAlarm] = useState([
    {
      id: 1,
      time: "06:00",
      description: "Wake up early",
      active: false,
      repeat: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    { id: 2, time: "08:30", description: "Morning meeting", active: false, date: "2023-03-07" },
    { id: 3, time: "12:00", description: "Lunch break", active: false },
    { id: 4, time: "06:44", description: "End of work day", active: true },
    { id: 5, time: "09:00", description: "Relax and watch TV", active: false },
  ]);

  const [allAlarms, setAllAlarms] = useState([]);
  const [schoolUsers, setSchoolUsers] = useState([]);

  const currentDayOfWeek = new Date().toLocaleString("en-US", { weekday: "short" }).substring(0, 3);

  const [selectedDay, setSelectedDay] = useState(currentDayOfWeek);

  const [currentMode, setCurrentMode] = useState("鬧鐘");
  function handleDayClick(day) {
    setSelectedDay(day);
  }

  function promptForDeviceID() {
    let deviceID = localStorage.getItem("deviceID");

    if (!deviceID) {
      // Prompt the user to enter a device ID
      deviceID = window.prompt("Please enter a unique device ID:");
      localStorage.setItem("deviceID", deviceID);
    }

    return deviceID;
  }

  useEffect(() => {
    // Reset the lastSeen whenever the alarms all changed
    const deviceID = promptForDeviceID();
    const userId = getAuth(app).currentUser.uid;
    const userName = getAuth(app).currentUser.email;
    const docRef = doc(db, `alarmDevice/${userName}/device`, deviceID);
    getDoc(docRef).then((doc) => {
      if (!doc.exists()) {
        console.log("No such document!");
      } else {
        console.log("Document data:", doc.data());
      }
    });

    setDoc(docRef, { lastSeen: Timestamp.now(), deviceID: deviceID, userId: userId, name: userName }, { merge: true });
  }, [allAlarms]);

  useEffect(() => {
    {
      // Set up a listener for the 'alarms' collection
      const q = query(collection(db, "users"), where(`schools.${schoolId}`, "==", true));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log(snapshot.docs, "alarms Accountable");
        const schoolsUser = snapshot.docs
          .filter((doc) => doc.data().alarmAccountable?.[schoolId] == true)
          .map((doc) => ({ ...doc.data(), id: doc.id }));

        setSchoolUsers(schoolsUser);
      });

      // Return a cleanup function to clear all timeouts and the Firestore listener
      return () => {
        unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    // ANCHOR Get all alarms regardless of weekday
    const repeatAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", false)
      // orderBy("time", "asc")
    );

    const unsubscribeRepeatAlarm = onSnapshot(repeatAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setAllAlarms((prevAlarms) => {
        const updatedAlarms = prevAlarms.filter((alarm) => alarm.oneOff);

        let allAlarms = [...updatedAlarms, ...alarmsData];
        // sort by time, ascending
        console.log(allAlarms, "repeatedAlarms");
        allAlarms.sort((a, b) => {
          a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
          b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

          return a.numberTime - b.numberTime;
        });
        return allAlarms;
      });
    });

    // get the start of week and end of week
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date();
    endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));
    // set time to 0:00 of start of week and 23:59 of end of week
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999);

    const oneOffAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", true),
      where("datetime", ">=", startOfWeek),
      where("datetime", "<=", endOfWeek),
      orderBy("datetime", "asc")
    );

    const unsubscribeOneOffAlarm = onSnapshot(oneOffAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setAllAlarms((prevAlarms) => {
        const updatedAlarms = prevAlarms.filter((alarm) => !alarm.oneOff);

        let allAlarms = [...updatedAlarms, ...alarmsData];
        console.log(allAlarms, "allAlarms");
        // sort by time, ascending
        allAlarms.sort((a, b) => {
          a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
          b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

          return a.numberTime - b.numberTime;
        });
        return allAlarms;
      });
    });

    // Return a cleanup function to clear all timeouts and the Firestore listener
    return () => {
      todayAlarm.forEach((alarm) => clearTimeout(alarm.timeoutId));
      unsubscribeRepeatAlarm();
      unsubscribeOneOffAlarm();
    };
  }, []);

  useEffect(() => {
    // ANCHOR Get today's alarm clock
    const repeatAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("repeat", "array-contains", currentDayOfWeek),
      where("oneOff", "==", false),
      orderBy("time", "asc")
    );

    const unsubscribeRepeatAlarm = onSnapshot(repeatAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      console.log("received new data", alarmsData);
      setTodayAlarm((prevAlarms) => {
        // Update only active alarms in the state
        const updatedAlarms = prevAlarms.filter((alarm) => alarm.oneOff);
        let allAlarms = [...updatedAlarms, ...alarmsData];
        // sort by time, ascending
        allAlarms.sort((a, b) => {
          a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
          b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

          return a.numberTime - b.numberTime;
        });
        return allAlarms;
      });
    });
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);

    const oneOffAlarmQuery = query(
      collection(db, "alarms"),
      where("schoolId", "==", schoolId),
      where("department", "==", department),
      where("oneOff", "==", true),
      // the where clause should be whether the alarm datetime is today bigger than the 0:00 of today and smaller than the 23:59 of today
      where("datetime", ">=", startOfDay),
      where("datetime", "<=", endOfDay),
      orderBy("datetime", "asc")
    );

    const unsubscribeOneOffAlarm = onSnapshot(oneOffAlarmQuery, (snapshot) => {
      const alarmsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setTodayAlarm((prevAlarms) => {
        const updatedAlarms = prevAlarms.filter((alarm) => !alarm.oneOff);
        let allAlarms = [...updatedAlarms, ...alarmsData];

        allAlarms.sort((a, b) => {
          a.numberTime = parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
          b.numberTime = parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);

          return a.numberTime - b.numberTime;
        });
        return allAlarms;
      });
    });

    // Return a cleanup function to clear all timeouts and the Firestore listener
    return () => {
      todayAlarm.forEach((alarm) => clearTimeout(alarm.timeoutId));
      unsubscribeRepeatAlarm();
      unsubscribeOneOffAlarm();
    };
  }, []);

  useEffect(() => {
    let newActiveAlarms = [...activeAlarms];
    todayAlarm.forEach((alarm) => {
      const now = new Date();
      const alarmTime = new Date(`${now.toDateString()} ${alarm.time}`);
      const timeUntilAlarm = alarmTime.getTime() - now.getTime();
      const repeatDays = alarm.repeat?.map((day) => day.substring(0, 3));
      const today = now.toLocaleString("en-US", { weekday: "short" }).substring(0, 3);

      if (timeUntilAlarm > 0 && alarm.active && repeatDays?.includes(today)) {
        const timeoutId = setTimeout(() => {
          newActiveAlarms.push(alarm);

          setShowAlertModal(true);
        }, timeUntilAlarm);
        alarm.timeoutId = timeoutId;
      } else if (timeUntilAlarm > 0 && alarm.active && alarm.oneOff) {
        const timeoutId = setTimeout(() => {
          newActiveAlarms.push(alarm);

          setShowAlertModal(true);
        }, timeUntilAlarm);
        alarm.timeoutId = timeoutId;
      }
    });

    // console.log(todayAlarm, "todayAlarm", new Date());
    setActiveAlarms(newActiveAlarms);
  }, [todayAlarm]);

  function handleAdd({ time, description, repeat, oneOff, oneOffDateTime }) {
    const db = getFirestore(app);
    const alarmsCollection = collection(db, "alarms");
    if (oneOff) {
      let thisDate = new Date(oneOffDateTime);

      const newAlarm = {
        time: time,
        description: description,
        repeat: [],
        createdAt: Timestamp.now(),
        active: true,
        schoolId: schoolId,
        department: department,
        oneOff: oneOff,
        datetime: thisDate,
      };
      addDoc(alarmsCollection, newAlarm)
        .then(() => {
          console.log("Alarm added successfully.");
        })
        .catch((error) => {
          console.error("Error adding alarm: ", error);
        });
    } else {
      const newAlarm = {
        time: time,
        description: description,
        repeat: repeat,
        createdAt: Timestamp.now(),
        active: true,
        schoolId: schoolId,
        department: department,
        oneOff: false,
      };
      addDoc(alarmsCollection, newAlarm)
        .then(() => {
          console.log("Alarm added successfully.");
        })
        .catch((error) => {
          console.error("Error adding alarm: ", error);
        });
    }
  }

  const deleteAlarmInFirestore = (alarm) => {
    // first double check if the user wants to delete the alarm

    console.log("delete alarm");
    // delete it from firebase

    const db = getFirestore(app);
    const alarmsCollection = collection(db, "alarms");
    deleteDoc(doc(alarmsCollection, alarm.id));
  };

  const handleViewTurnedActive = (id) => {
    setTodayAlarm(todayAlarm.map((alarm) => (alarm.id === id ? { ...alarm, active: !alarm.active } : alarm)));
  };
  const handleEditTimeChange = (event, id) => {
    setTodayAlarm(todayAlarm.map((alarm) => (alarm.id === id ? { ...alarm, time: event.target.value } : alarm)));
  };
  const playSound = () => {
    const audio = new Audio(soundFile);
    console.log("played");
    audio.play();
  };

  function addMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const handleSnoozeClick = (alarm, defaultTime = 1, alarmTitle = "") => {
    // Snooze for 5 minutes

    handleSetToDoneClick(alarm);
    console.log("snooze", alarmTitle, alarm);
    const snoozeTime = new Date(Date.now() + defaultTime * 60 * 1000);
    const formatTime = (date) => {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    };

    const newTempAlarm = {
      ...alarm,
      title: alarmTitle + `貪睡${defaultTime}分鐘`,

      time: formatTime(snoozeTime),
      active: true,
      oneOff: true,
      datetime: new Date(),
      notifyingStatus: "no",
    };

    addDoc(collection(db, "alarms"), newTempAlarm);

    // setActiveAlarms(activeAlarms.filter((activeAlarm) => activeAlarm.id !== alarm.id));
  };

  const handleSetToDoneClick = (alarm) => {
    const newAlarm = { ...alarm, doneAt: Timestamp.now() };
    updateAlarmInFirestore(newAlarm);
    // filter out the activeAlarms
    setActiveAlarms(activeAlarms.filter((activeAlarm) => activeAlarm.id !== alarm.id));
  };

  const updateAlarmInFirestore = (newAlarm) => {
    updateDoc(doc(db, "alarms", newAlarm.id), newAlarm);
    // db.collection("alarms").doc(newAlarm.id).update(newAlarm);
  };

  const handleLogOff = () => {
    const auth = getAuth(app);
    signOut(auth).then(() => {
      window.location.reload();
    });
  };

  async function createTextToSpeech(text, newAlarm) {
    console.log("createTextToSpeech");

    textToSpeech({ text: text })
      .then((result) => {
        const audio = new Audio(result.data.audioUrl);
        audio.play();
        // save the data to firebase storage and get the url
        const storageRef = ref(storage, `audio/${newAlarm.id}.mp3`);
        console.log(result.data.audioUrl, "base64 ?");
        // remove this part from result.data.audioUrl "data:audio/MP3;base64,"

        // let base64Part = result.data.audioUrl.split(",")[1];
        const task = uploadString(storageRef, result.data.audioUrl, "data_url");
        task.then((snapshot) => {
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // update the alarm with the audio url
            const updatedAlarm = { ...newAlarm, audioUrl: downloadURL };
            updateAlarmInFirestore(updatedAlarm);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="container mt-5">
      {showAlertModal && (
        <AlarmClockModal
          showModal={showAlertModal}
          alarms={activeAlarms}
          setAlarms={setActiveAlarms}
          schoolUsers={schoolUsers}
          handleSnoozeClick={handleSnoozeClick}
          handleSetToDoneClick={handleSetToDoneClick}
          handleClose={() => setShowAlertModal(false)}
          createTextToSpeech={createTextToSpeech}
        />
      )}

      <AddAlarmModal showModal={showAddModal} handleClose={() => setAddShowModal(false)} handleAdd={handleAdd} department={department} />

      <div>
        <ButtonGroup className="mb-3" aria-label="Days of the week">
          {["鬧鐘", "ECB", "門口表", "下週班務"].map((item) => (
            <Button
              key={item}
              variant={item === currentMode ? "outline-primary" : "outline-secondary"}
              onClick={() => {
                setCurrentMode(item);
              }}
              active={item === currentMode}
            >
              {item}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <p>最後更新日：2024-5-10</p>

      {currentMode === "鬧鐘" ? (
        <AlarmActualList
          allAlarms={allAlarms}
          currentDayOfWeek={currentDayOfWeek}
          department={department}
          schoolId={schoolId}
          handleViewTurnedActive={handleViewTurnedActive}
          handleEditTimeChange={handleEditTimeChange}
          setAddShowModal={setAddShowModal}
          updateAlarmInFirestore={updateAlarmInFirestore}
          deleteAlarmInFirestore={deleteAlarmInFirestore}
          createTextToSpeech={createTextToSpeech}
        />
      ) : currentMode === "ECB" ? (
        <ECBList />
      ) : currentMode === "下週班務" ? (
        <NextWeekAction schoolId={schoolId} />
      ) : currentMode === "門口表" ? (
        <CheckAttendance schoolId={schoolId} />
      ) : null}

      <div className="mt-5">
        {/* Button to log off  */}
        <button type="button" className="btn btn-outline-danger" onClick={() => handleLogOff()}>
          登出
        </button>
      </div>
    </div>
  );
}

export default AlarmClockList;
