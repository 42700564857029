import React, { useState, useEffect } from "react";

import app from "../../firebase";
import { getFirestore, doc, setDoc, updateDoc, addDoc, Timestamp, onSnapshot, query, collection, where } from "firebase/firestore";
import { Modal, Button, Form, FormGroup, FormLabel, FormControl, ButtonGroup } from "react-bootstrap";

function AlarmClockDisplay({ alarm, isEditing, handleEditClick, createTextToSpeech }) {
  const [audioSrc, setAudioSrc] = useState(alarm.audioUrl); // state to hold the audio URL

  function playAudio(url) {
    setAudioSrc(url);
  }
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{alarm.time}</h5>
        <h5 className="card-text flex-fill">{alarm.title}</h5>
        <div className="d-flex flex-row mb-3">
          <p className="card-text flex-fill">{alarm.description}</p>
          {/* Add a button to do text to speech  */}
          {alarm.audioUrl ? (
            <div>
              {/* <button onClick={() => playAudio("YOUR_FIREBASE_AUDIO_URL")}>Play Audio</button> */}
              <audio src={audioSrc} controls autoPlay={false} />
              <button className="btn btn-secondary" onClick={() => createTextToSpeech(alarm.description, alarm)}>
                <p> 重存音檔</p>
              </button>
            </div>
          ) : (
            <button className="btn btn-secondary" onClick={() => createTextToSpeech(alarm.description, alarm)}>
              <p> 存音檔</p>
            </button>
          )}
        </div>
        {/* Show which day it repeats */}
        {alarm.oneOff === true ? (
          <div className="d-flex flex-row mb-3">
            <p className="card-text flex-fill">一次性的日期: {alarm.datetime?.toDate().toDateString()}</p>
          </div>
        ) : (
          <div className="d-flex flex-row mb-3">
            <p className="card-text flex-fill">週期性:</p>

            {alarm.repeat?.map((day) => (
              <div key={day} className="form-check-inline mx-4">
                <input type="checkbox" className="form-check-input" name="active" checked disabled />
                <label className="form-check-label">{day}</label>
              </div>
            ))}
          </div>
        )}

        <button className={`btn ${isEditing ? "btn-outline-danger" : "btn-secondary"} mt-3 mx-2`} type="button" onClick={handleEditClick}>
          {isEditing ? "取消編輯" : "編輯鬧鐘"}
        </button>
      </div>
    </div>
  );
}

function AlarmClockEdit({ alarm, isEditing, handleEditClick, handleSaveClick, handleDeleteClick, createTextToSpeech }) {
  const [editedAlarm, setEditedAlarm] = useState(alarm);
  function handleInputChange(event, curDay) {
    let { name, value } = event.target;

    if (name === "active") {
      setEditedAlarm({ ...editedAlarm, active: !editedAlarm.active });
    }
    if (name.includes("repeat")) {
      let newRepeat = [...editedAlarm.repeat];
      if (newRepeat.includes(curDay)) {
        newRepeat = newRepeat.filter((day) => day !== curDay);
      } else {
        newRepeat.push(curDay);
      }

      setEditedAlarm({ ...editedAlarm, repeat: newRepeat });
    } else {
      setEditedAlarm({ ...editedAlarm, [name]: value });
    }
  }

  const sendTextToChatGPTForPoliteResponse = async (userText) => {
    // What is the best prompt to create a good response ?
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [
            {
              role: "system",
              content:
                "Imagine you are a benovolent boss, with a less formal more friendly way of communication. Rewrite these commands into very kind and polite commands. Keep it within two or three sentences. Use traditional chinese unless the phrase in mentioned in english.",
            },
            { role: "user", content: "3:00 檢查及關閉所有電源" },
            {
              role: "assistant",
              content:
                "三點鐘嘍！大家辛苦了！在離開之前，能麻煩大家檢查一下所有的電源，確保它們都已經關閉嗎？這樣能幫我們節省電費和保護環境哦！謝謝大家！",
            },
            { role: "user", content: "17:15 下課時段站門口注意家長學生是否有找到家長再出去，學生要講May I sign out?" },
            {
              role: "assistant",
              content:
                "五點十五嘍！學生下課的時候，請去門口看看有沒有學生和家長一起出去哦！學生離開前，請記得跟老師講一聲 'May I sign out?'！謝謝大家一起維護學生的安全！",
            },

            {
              role: "user",
              content: `${userText}`,
            },
          ],
        }),
      });
      const data = await response.json();
      console.log(data.choices[0].message.content, "content");
      setEditedAlarm({ ...editedAlarm, description: data.choices[0].message.content });
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{editedAlarm.time}</h5>
        <input type="time" name="time" value={editedAlarm.time} onChange={(e) => handleInputChange(e)} />
        <div className="d-flex flex-row mt-2">
          <label for="exampleFormControlInput1" className="form-label">
            標題
          </label>
        </div>
        <p>{editedAlarm.id}</p>
        <div className="d-flex flex-row mb-3">
          <input
            type="text"
            name="title"
            className="form-control flex-fill"
            value={editedAlarm.title}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <button
          className="btn"
          style={{ backgroundColor: "pink", color: "#000000" }}
          onClick={() => sendTextToChatGPTForPoliteResponse(`${editedAlarm.time} ${editedAlarm.description}`)}
        >
          <p> AI改寫</p>
        </button>
        <div className="d-flex flex-row mt-2">
          <label for="exampleFormControlInput1" className="form-label">
            語音朗讀
          </label>
        </div>
        <div className="d-flex flex-row mb-3">
          <input
            type="text"
            name="description"
            className="form-control flex-fill"
            value={editedAlarm.description}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        {alarm.oneOff === true ? (
          <div className="d-flex flex-row mb-3">
            <p className="card-text flex-fill">一次性的日期: {alarm.datetime?.toDate().toDateString()}</p>
          </div>
        ) : (
          <div className="d-flex flex-row mb-3">
            <p className="card-text flex-fill">週期性:</p>
            {/* Map through alarm.repeat */}
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
              <div key={day} className="form-check-inline mx-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={"repeat" + day}
                  checked={editedAlarm.repeat?.includes(day)}
                  onChange={(e) => handleInputChange(e, day)}
                />
                <label className="form-check-label">{day}</label>
              </div>
            ))}
          </div>
        )}

        <button className={`btn ${isEditing ? "btn-outline-danger" : "btn-secondary"} mt-3 mx-2`} type="button" onClick={handleEditClick}>
          {isEditing ? "取消編輯" : "編輯鬧鐘"}
        </button>

        <button className="btn btn-success mt-3 mx-2" type="button" onClick={() => handleSaveClick(editedAlarm)}>
          儲存編輯
        </button>

        <button
          className="btn btn-danger mt-3 mx-2"
          type="button"
          onClick={() => {
            const confirmResult = window.confirm("Are you sure you want to delete this alarm?");
            if (confirmResult) {
              console.log(confirmResult);
              handleDeleteClick(alarm);
            }
          }}
        >
          「刪除」鬧鐘
        </button>
      </div>
    </div>
  );
}

function AlarmClockCard({ alarm, handleViewTurnedActive, handleEditTimeChange, handleSaveClick, handleDeleteClick, createTextToSpeech }) {
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  function toggleDetails() {
    setShowDetails(!showDetails);
  }

  function handleEditClick() {
    setIsEditing((prev) => !prev);
  }

  if (isEditing) {
    return (
      <>
        <AlarmClockEdit
          alarm={alarm}
          handleEditTimeChange={handleEditTimeChange}
          handleViewTurnedActive={handleViewTurnedActive}
          toggleDetails={toggleDetails}
          showDetails={showDetails}
          handleSaveClick={(alarm) => {
            handleSaveClick(alarm);
            setIsEditing(false);
          }}
          isEditing={isEditing}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          createTextToSpeech={createTextToSpeech}
        />
      </>
    );
  } else {
    return (
      <>
        <AlarmClockDisplay
          alarm={alarm}
          handleViewTurnedActive={handleViewTurnedActive}
          toggleDetails={toggleDetails}
          showDetails={showDetails}
          isEditing={isEditing}
          handleEditClick={handleEditClick}
          createTextToSpeech={createTextToSpeech}
        ></AlarmClockDisplay>
      </>
    );
  }
}

export default AlarmClockCard;
